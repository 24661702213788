const jwt = require("jsonwebtoken");


const wrapJWT = async function (authorization_token, secretKey) {
    try {
        if(!authorization_token){
            throw {
                "status" : "auth token empty"
            }
        }
        console.log("auth token",authorization_token);
        let jwt_token = jwt.sign(
            {
                auth_token : authorization_token // as string cannot have a property of expiry so converted to object
            }
            , secretKey,
            {
                expiresIn : 30 // in seconds
            }
        );
        return jwt_token;
    } catch(err) {
        console.log("Error in jwt wrap", err);
        throw err;
    }
}

module.exports = {
    wrapJWT
}
