//check isEmpty
let isEmpty = (data) => {
    if (data == undefined || data == "")
        return true;
    else
        return false;
}

function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

function isMultiCustomerBackopEmailUnformator(email) {
    const emailNameArr = email?.split('+')
    const emailDomainArr = email?.split('@')

    return emailNameArr[0] + "@" + emailDomainArr[emailDomainArr.length-1]
}

function padDoubleDigits(number) {
    if (number >= 0)
        return number < 10 ? `0${number}` : number.toString();
    else
        return -number < 10 ? `-0${-number}` : number.toString();
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

function radians(deg) { return deg * Math.PI / 180; };

function distributeAngles(me, total) {
    return me / total * 360;
}

function rgb(r, g, b) {

    if (g == undefined) g = r;
    if (b == undefined) b = r;
    return 'rgb(' + clamp(Math.round(r), 0, 255) + ', ' + clamp(Math.round(g), 0, 255) + ', ' + clamp(Math.round(b), 0, 255) + ')';

}

function clamp(value, min, max) {
    return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

function map(value, min1, max1, min2, max2, clampResult) {
    var returnvalue = ((value - min1) / (max1 - min1) * (max2 - min2)) + min2;
    if (clampResult) {
        return clamp(returnvalue, min2, max2);
    } else { return returnvalue; };
}

let getPosition = function () {
    let options = { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 };
    
    return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

let convertToRinger = (seconds) => {
    let mins = Math.floor(seconds / 60);
    let secs = seconds - mins * 60;
    return `${
        mins.toString().length == 1 ? "0" + mins : mins
        }:${secs.toString().length == 1 ? "0" + secs : secs}`;
}

let rightClickBlock = (e) => {
    e.preventDefault();
}

let disableInspect = (e) => {
    if (e.keyCode == 123) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
    }
    if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
    }
}

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function convertTo24HourFormat(time) {
    let [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'AM' && hours === 12) {
        hours = 0;
    } else if (modifier === 'PM' && hours !== 12) {
        hours += 12;
    }

    let formattedHours = String(hours).padStart(2, '0');
    let formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}

function convertTo12HourFormat(time) {
    let [hours, minutes] = time.split(':').map(Number);
    let modifier = 'AM';

    if (hours >= 12) {
        modifier = 'PM';
        if (hours > 12) {
            hours -= 12;
        }
    } else if (hours === 0) {
        hours = 12;
    }

    let formattedHours = String(hours).padStart(2, '0');
    let formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes} ${modifier}`;
}
// window.getRandomSignature = getRandomSignature;
export {
    isEmpty,
    padDoubleDigits,
    isMultiCustomerBackopEmailUnformator,
    getRandomInt,
    radians,
    distributeAngles,
    rgb,
    map,
    rightClickBlock,
    disableInspect,
    getPosition, convertToRinger,
    delay,
    deepEqual,
    convertTo24HourFormat,
    convertTo12HourFormat
};